























import { ImageSourceSet } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import HomepageVideo from '../HomepageVideo.vue';

@Component({
  components: {
    HomepageVideo,
  },
})
export default class Masthead extends Vue {
    @Prop()
    private readonly darken!: boolean;

    @Prop({ default: false })
    private readonly noPadding!: boolean;

    @Prop()
    private readonly image!: string | ImageSourceSet;

    @Prop()
    private readonly relativeContent!: boolean;
}
