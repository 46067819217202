import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ebooks/sellers-guide-to-success',
    name: 'Sellers Guide to Success',
    component: () => import('../views/SellersGuideToSuccess.vue'),
  },
  {
    path: '/any-agent-can-sell-your-home',
    name: 'Any Agent Can Sell Your Home',
    component: () => import('../views/AnyAgentCanSellYourHome.vue'),
  },
  {
    path: '/sold-properties',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/ebooks/investors-guide-to-success',
    name: 'Investors Guide to Success',
    component: () => import('../views/LookingToInvest.vue'),
  },
  {
    path: '/invest-in-sheldon-realty',
    name: 'Invest In Sheldon Realty',
    component: () => import('../views/InvestInSheldonRealty.vue'),
  },
  {
    path: '/holiday-let',
    name: 'Holiday Let Cairns',
    component: () => import('../views/HolidayLet.vue'),
  },
  {
    path: '/properties-for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/open-for-inspection',
    name: 'Open for Inspection',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/buyers-agent',
    name: 'Buyers Agent',
    component: () => import('../views/BuyersAgent.vue'),
  },
  {
    path: '/properties-for-rent',
    name: 'Properties for Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/notice-of-intention-to-leave',
    name: 'Notice of Intention to Leave',
    component: () => import('../views/NoticeOfLeave.vue'),
  },
  {
    path: '/maintenance-request-form',
    name: 'Maintenance Request Form',
    component: () => import('../views/MaintenanceRequestForm.vue'),
  },
  {
    path: '/sellers-tips',
    name: 'Sellers Tips',
    component: () => import('../views/SellersTips.vue'),
  },
  {
    path: '/get-an-appraisal',
    name: 'Get an Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/buyers-tips',
    name: 'Buyers Tips',
    component: () => import('../views/BuyersTips.vue'),
  },
  {
    path: '/investor-tips',
    name: 'Investor Tips',
    component: () => import('../views/InvestorTips.vue'),
  },
  {
    path: '/renting-tips',
    name: 'Renting Tips',
    component: () => import('../views/RentingTips.vue'),
  },
  {
    path: '/suburb-profiles',
    name: 'Suburb Profiles',
    component: () => import('../views/SuburbProfiles.vue'),
  },
  {
    path: '/employment-opportunities',
    name: 'Employment Opportunities',
    component: () => import('../views/EmploymentOpportunities.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/sign-up-video-course',
    name: 'Sign Up Video Course',
    component: () => import('../views/SignUpVideoCourse.vue'),
  },
  {
    path: '/meet-the-team',
    name: 'Meet the Team',
    component: () => import('../views/MeetTheTeam.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Details',
    meta: { hide: true },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: { hide: true },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: { hide: true },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: { hide: true },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: { hide: true },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/profiles/:id/appraisal',
    name: 'Profile Appraisal',
    meta: { hide: true },
    component: () => import('../views/AgentProfileAppraisal.vue'),
  },
  {
    path: '/purchaser-contract',
    name: 'Purchaser Contract',
    meta: { hide: true },
    component: () => import('../views/PurchaserContractForm.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  // Core Logic Plugin
  {
    path: '/free-property-report',
    name: 'Free Property Report',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/FreePropertyReport.vue'),
  },
  {
    path: '/property-report',
    name: 'Property Report',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReport.vue'),
  },
  {
    path: '/property-report-preview',
    name: 'Property Report Preview',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReportPreview.vue'),
  },
  {
    path: '/property-report-digital-appraisal',
    name: 'Property Report Digital Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReportDigitalAppraisal.vue'),
  },
  {
    path: '/rental-report',
    name: 'Rental Report',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReport.vue'),
  },
  {
    path: '/rental-report-preview',
    name: 'Rental Report Preview',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReportPreview.vue'),
  },
  {
    path: '/rental-report-digital-appraisal',
    name: 'Rental Report Digital Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/PropertyReportDigitalAppraisal.vue'),
  },
  {
    path: '/suburb-report',
    name: 'Suburb Report',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/SuburbReport.vue'),
  },
  {
    path: '/suburb-report-detail',
    name: 'Suburb Report Detail',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/SuburbReportDetail.vue'),
  },
  // Other
  {
    path: '/*',
    name: 'Not Found',
    meta: { hide: true },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/profiles/${slug.Slug}`,
                replace: true,
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    if (to.hash) return { selector: to.hash };

    return { x: 0, y: 0 };
  },
});

export default router;
