








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
    @Prop()
    private readonly href!: string;

    @Prop()
    private readonly externalLink!: boolean;

    onClick() {
      this.$emit('click');

      if (this.externalLink) {
        window.open(this.href, '_blank');
      } else {
        this.$router.push(this.href);
      }
    }
}
