







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Office, OfficeExternalLinkType } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import Navigation from './Navigation.vue';
import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    Navigation,
    NavigationLink,
  },
})
export default class Header extends Vue {
    private office: Office | null = null;

    private isStuck = false;

    private mobileNavActive = false;

    /* Configuration Options */
    private gradient = false;

    private fixed = false;

    private stickyNav = true;

    private eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

    private holidayLet = false;

    private buyersAgent = false;

    mounted() {
      this.checkScroll();

      API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
        this.office = office;
      });

      this.$router.afterEach(() => {
        this.mobileNavActive = false;
      });
    }

    get facebookLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Facebook)?.Url;
    }

    get youtubeLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.YouTube)?.Url;
    }

    get twitterLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Twitter)?.Url;
    }

    get googleMyBusinessLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.GoogleMyBusiness)?.Url;
    }

    get linkedinLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.LinkedIn)?.Url;
    }

    get pinterestLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Pinterest)?.Url;
    }

    get instagramLink(): string | undefined {
      return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Instagram)?.Url;
    }

    checkScroll(): void {
      this.isStuck = window.scrollY > 140;
    }

    created() {
      window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }
}
