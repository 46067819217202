









































import { Testimonial } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Embeddable from '../Common/Embeddable.vue';

@Component({
  components: {
    Embeddable,
  },
})
export default class TestimonialCardRounded extends Vue {
  @Prop()
  private readonly testimonial!: Testimonial;

  private cleanedContent = ''

  mounted() {
    this.cleanContent();
  }

  getYouTubeId() {
    // eslint-disable-line
    const videoLink = this.testimonial.VideoLink.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=))([\w-]{10,12})\b/);
    let videoId = '';
    if (videoLink !== null && videoLink.length > 0) {
      videoId = videoLink![1];
    }

    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }

  get formattedAddress(): string {
    const parts = [];

    if (this.testimonial.Address) parts.push(this.testimonial.Address);
    if (this.testimonial.Suburb) parts.push(this.testimonial.Suburb);

    return parts.join(', ');
  }

  @Watch('testimonial')
  cleanContent(): void {
    const el = document.createElement('div');

    el.innerHTML = this.testimonial.Description;

    this.cleanedContent = el.innerText;
  }

  popupActive = false

  showTestimonial(): void {
    this.popupActive = true;
  }

  hideTestimonial(): void {
    this.popupActive = false;
  }
}
