




























import { Config } from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { ContextItemType, CustomContextItem } from 'client-website-ts-library/types/Context';
import { GeoSearchFeature } from 'client-website-ts-library/types/Geocoding/GeoSearchResult';
import { Component, Vue } from 'vue-property-decorator';
import AddressAutocomplete from './AddressAutocomplete.vue';
import Form from './Forms/Form.vue';
import Modal from './Modal.vue';

@Component({
  components: {
    AddressAutocomplete,
    Modal,
    Form,
  },
})
export default class WhatsYourHomeWorth extends Vue {
  private result: GeoSearchFeature | null = null;

  private isFormShown = false;

  private formData: Context | null = null;

  private submitted = false;

  showForm(suburb?: string, address?: string) {
    if (this.isFormShown) return;

    const a = address ?? '';

    const s = suburb ?? '';

    if (a === '') return;

    if (s === '') return;

    this.formData = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.CustomData,
          Id: '',
          Key: 'Address',
          Value: address ?? '',
        },
        {
          Type: ContextItemType.CustomData,
          Id: '',
          Key: 'Suburb',
          Value: suburb ?? '',
        } as CustomContextItem,
      ],
    };

    this.isFormShown = true;
  }

  setSelection(result: GeoSearchFeature) {
    this.result = result;

    this.showForm(result.Suburb, result.Name);
  }

  handleFormSubmitted() {
    this.submitted = true;
  }
}
