
















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import VideoPlayerSource from '@/app_code/VideoPlayerSource';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

@Component({
  components: {
  },
})
export default class VideoTile extends Mixins(View) {
  @Prop({ default: false })
  private loop!: boolean;

  @Prop({ default: false })
  private autoplay!: boolean;

  @Prop()
  private readonly ratio!: number;

  @Prop()
  private readonly poster!: string;

  @Prop()
  private readonly label!: string;

  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly video!: VideoPlayerSource;

  @Prop({ default: false })
  private externalLink!: boolean;

  @Prop({ default: '' })
  private animateText!: string;

  private office: Office | null = null;

  mounted() {
    this.loadOffice();
  }

  onClicked() {
    if (!this.externalLink) {
      this.$router.push({ path: this.href });
    } else {
      window.open(this.href, '_blank');
    }
  }

  loadOffice(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  play() {
    const vidElem = this.$refs.player as HTMLVideoElement;
    vidElem.muted = true;
    vidElem.play();
  }

  pause() {
    const vidElem = this.$refs.player as HTMLVideoElement;
    vidElem.muted = true;
    vidElem.pause();
  }
}
