











import { TestimonialFilter } from 'client-website-ts-library/filters';
import { API } from 'client-website-ts-library/services';
import { Testimonial } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TestimonialCard from './TestimonialCard.vue';
import TestimonialCardRounded from './TestimonialCardRounded.vue';

@Component({
  components: {
    TestimonialCard,
    TestimonialCardRounded,
  },
})
export default class TestimonialCards extends Vue {
  private testimonials: Testimonial[] = [];

  @Prop()
  private readonly filter!: TestimonialFilter;

  @Prop({ default: 0 })
  private readonly displayLimit!: number;

  @Prop({ default: 3 })
  private readonly perRow!: number;

  @Prop({ default: false })
  private readonly displayRounded!: boolean;

  mounted() {
    this.updateTestimonials();
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      if (this.displayLimit > 0 && testimonials.length > this.displayLimit) {
        this.testimonials = testimonials.slice(0, this.displayLimit);
      } else {
        this.testimonials = testimonials;
      }

      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);
    });
  }
}
