












import { ImageSourceSet } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContentRow extends Vue {
    @Prop()
    private readonly backgroundColour!: string;

    @Prop()
    private readonly darken!: boolean;

    @Prop()
    private readonly lighten!: boolean;

    @Prop()
    private readonly backgroundImage!: string | ImageSourceSet;

    @Prop()
    private readonly noPadding!: boolean;

    @Prop()
    private readonly hasMarginBottom!: boolean;

    @Prop()
    private readonly hasMarginTop!: boolean;
}
